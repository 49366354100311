<template>
  <head>
    <title>コラム</title>
  </head>
  <aheader/>

  <div class="block">
  <div class="container is-fluid">
    <div class="section">
      <p class="title">
        コラム
      </p>
    </div>
    <div class="block">
      <div class="content is-normal">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h4 style="line-height : 220%; text-align: left ;">寝具専門店として長年蓄えてきた、布団に関する豆知識をお伝えします。あまり知られていない布団の歴史や、布団選びの知識などを掲載してまいります。これを読んで、毎日「ぐっすり」眠れる環境を整えてみてください！</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p>-------------------------------</p>
  </div>


  <!-- <div class="block" v-for="column,index in columns" :key="index">
    <section class="hero">
      <div class="hero-body">
        <article :class="`message is-${column.type}`">
          <div class="message-header">
            <p>{{column.title}}</p>
            <p>{{$dayjs(column.date.toDate()).format('YYYY年M月D日(dd)')}}</p>
          </div>
          <div class="message-body">
            <article class="media">
              <figure class="media-left">
                <p class="image is-349x500">
                  <img :class="{'main-image':column.image}" :src="column.image">
                </p>
              </figure>
              <div class="media-content">
                <div class="content is-normal" style="text-align:left" v-html="column.contents"></div>
                <nav class="level is-mobile">
                  <div class="level-left">
                    <a class="level-item">
                      <span class="icon is-small"><i class="fas fa-reply"></i></span>
                    </a>
                    <a class="level-item">
                      <span class="icon is-small"><i class="fas fa-retweet"></i></span>
                    </a>
                    <a class="level-item">
                      <span class="icon is-small"><i class="fas fa-heart"></i></span>
                    </a>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        </article>
      </div>
    </section>
  </div> -->

  <!-- <div class="block" v-for="column,index in columns" :key="index">
    <div class="column is-8 is-offset-2">
      <article :class="`message is-${column.type}`">
        <div class="message-header">
          <p>{{column.title}}</p>
          <p>{{$dayjs(column.date.toDate()).format('YYYY年M月D日(dd)')}}</p>
        </div>
        <div class="message-body">
          <article class="media">
            <figure class="media-left">
              <p class="image is-349x500">
                <img :class="{'main-image':column.image}" :src="column.image">
              </p>
            </figure>
            <div class="media-content">
              <div class="content is-normal" style="text-align:left" v-html="column.contents"></div>
              <nav class="level is-mobile">
                <div class="level-left">
                  <a class="level-item">
                    <span class="icon is-small"><i class="fas fa-reply"></i></span>
                  </a>
                  <a class="level-item">
                    <span class="icon is-small"><i class="fas fa-retweet"></i></span>
                  </a>
                  <a class="level-item">
                    <span class="icon is-small"><i class="fas fa-heart"></i></span>
                  </a>
                </div>
              </nav>
            </div>
          </article>
        </div>
      </article>
    </div>
  </div> -->



  <div class="block" v-for="column,index in columns" :key="index">
    <div class="column is-8 is-offset-2">
        <article :class="`message is-${column.type}`">
            <div class="message-header">
              <p>{{column.title}}</p>
              <p>{{$dayjs(column.date.toDate()).format('YYYY年M月D日(dd)')}}</p>
            </div>
            <div class="message-body">
              <h2 class="title">{{column.title2}}</h2>
              <p class="column is-8 is-offset-2" style="text-align: right ;">{{column.img_explain}}</p>
              <img :class="{'main-image':column.image}" :src="column.image">
              <div class="block">
                <div class="content is-normal">
                  <div class="columns">
                    <div class="column is-10 is-offset-1">
                      <h5 style="line-height : 220%; text-align: left ;">{{column.contents}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </article>
    </div>
  </div>





  <afooter/>
</template>

<style scoped>
.main-image {
  width: 40vw;
}


</style>

<script>
import {
  collection,
  getDocs,
  orderBy,
  where,
  query
} from "firebase/firestore";

export default {
  data() {
    return {
      columns: []
    }
  },
  created() {
    this.get_columns();
  },
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    async get_columns() {
      const q = query(
        collection(this.$firestore_db, 'columns'),
        where("publish", "==", true),
        orderBy("date", "desc")
      );
      const qs = await getDocs(q);
      this.columns = qs.docs.map(doc => doc.data());
    }
  }
}
</script>
